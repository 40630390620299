:root {
  --dark-green: #006532;
  --dark-green-trans: rgba(0, 101, 50, 0.5);
  --light-green-trans: rgba(0, 101, 50, 0.25);
  --header-bg: #a8cae8;
  /* --main-bg: #8fbbe2; */
  --main-bg: #aecfee;
  --red-label: #990000;
  --main-bg-trans: rgba(143, 187, 226, 0.5);
  --medium-blue: #1D70B7;
  --medium-blue-trans-33: rgba(30, 112, 183, 0.33);
  --medium-blue-trans: rgba(30, 112, 183, 0.5);
  --medium-blue-trans-60: rgba(30, 112, 183, 0.60);
  --white-trans-25: rgba(255, 255, 255, 0.25);
  --white-trans-33: rgba(255, 255, 255, 0.33);
  --white-trans: rgba(255, 255, 255, 0.5);
  --white-trans-60: rgba(255, 255, 255, 0.60);
  --white-trans-75: rgba(255, 255, 255, 0.75);
  --white-trans-80: rgba(255, 255, 255, 0.80);
  --faint-grey: #eeeeee;
  --dark-brown: #432918;
  --max-top-nav-width: 37em;
  --max-top-nav-width-l1-2: 35.25em;
  --max-top-nav-width-l1-3: 33.5em;
  --max-top-nav-width-l2: 31em;
  --max-top-nav-width-l3: 30em;
  --header-bottom: 15em;
  --header-bottom-12: 13.5em;
  --header-bottom-l3: 12.5em;
  --header-bottom-l4: 11em;
  --header-bottom-l5: 8em;
  --header-bottom-l6: 6em;
  --top-bg-height: 16.25em;
  --top-bg-height-12: 15em;
  --top-bg-height-l3: 13.5em;
  --top-bg-height-l4: 11em;
  --top-bg-height-l5: 10.5em;
  --top-bg-height-l6: 8.5em;
}

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=388a2ef0-565e-4204-95b8-e66b2ab2755a");
@font-face{
    font-family:"TT Marxiana W05 Grotesque";
    src:url("/fonts/6295087d-6c08-406d-ae8c-1eb4da7f0569.woff2") format("woff2"),url("/fonts/c237c41d-38c3-43e9-b29f-deb461e17bd3.woff") format("woff");
}
@font-face{
    font-family:"TT Marxiana W05 Antiqua";
    src:url("/fonts/31c78c7f-949b-4774-ae39-e57b447cf665.woff2") format("woff2"),url("/fonts/7a453d3e-5812-4f71-8f2a-3d0a66c61148.woff") format("woff");
}
@font-face{
    font-family:"TT Marxiana W05 Antiqua Italic";
    src:url("/fonts/b1039205-3d1f-4c23-b017-1942be14b2f0.woff2") format("woff2"),url("/fonts/16ba519d-83be-45b3-90f4-7d28e64696a9.woff") format("woff");
}

html {
  font-size: 100%;
}


body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--dark-green);
  background-color: var(--main-bg);
}

body,
i.icon span {
  font-family: "TT Marxiana W05 Antiqua";
}

.top-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: var(--top-bg-height-l6);
  background-color: var(--header-bg);
  z-index: 1;
  border-bottom: dotted 1px white;
}

header.inner-wrapper {
  min-height: 10em;
}

.top-bg,
.clouds-bg {
  pointer-events: none;
}

.clouds-bg {
  position: absolute;
  top: var(--header-bottom-l6);
  left: 0;
  right: 0;
  height: 108.533333333vw;
  background-color: var(--main-bg);
  background-image: url(/images/clouds-bg.jpg);
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: 0;
}

#root {
  z-index: 2;
}

#root .home-link,
#root .reset-cache {
  position: absolute;
  top: 0;
  right: 0;
}

#root .reset-cache {
  width: 1em;
  height: 1em;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  z-index: 20;
  cursor: pointer;
  padding: 1em;
  transform: scale(2);
}

#root .home-link {
  left: 0;
  bottom: 0;
}

#root .reset-cache:hover {
  opacity: 1;
}

#root .home .main-content .body,
#root .home .main-content h2.title,
#root .has-text-align-center {
  text-align: center;
}

#root .home .main-content > h1.title {
  opacity: 0;
}

#root input:focus {
  box-shadow: none;
  outline: none;
}

#root .ui.segment {
  background-color: var(--main-bg-trans);
}

body.show-overlay {
  overflow: hidden;
}

#root i.edit,
#root i.trash {
  cursor: pointer;
}

#root i.edit:hover {
  color: var(--dark-green);
}

#root i.trash:hover {
  color: var(--red-label);
}

body,
h1,
h2,
h3,
h4,
.icon span,
.icon em,
.call-to-actions > div > a span:last-child,
.main-images figcaption .description,
.main-images figcaption .caption em.label,
h5 {
  font-family: "TT Marxiana W05 Antiqua";
}

body,
h1,
footer h2,
.menu li.short {
  font-family: "TT Marxiana W05 Grotesque";
}

h1 {
  color: white;
}

main.inner-wrapper p,
main.inner-wrapper article {
  color: var(--dark-brown)
}

#root figcaption article.text p span,
#root figcaption article.text p strong,
#root figcaption article.text p em {
  display: inline;
}

.menu li {
  color: white;
}

header figcaption h2,
.menu li.long h3,
.call-to-actions > div > a span:first-child,
.menu li.long {
  font-family: "TT Marxiana W05 Antiqua Italic";
  font-weight: normal;
}

.menu li.long h3 {
  padding: 0;
  margin: 0.25em 0;
  display: inline-block;
  font-weight: normal;
  font-size: 0.8em;
}
#root .menu li.long {
  margin: 0 0.125em 0;
  height: 1.375em;
  padding: 0;
  border-bottom: dotted 1px white;
}

.container {
  position: relative;
}

#root div.ui.container {
  width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 1em 0 2em 0;
}

#root main.inner-wrapper div.ui.container {
  padding: 1em 0 2em 0;
}

#masonry-grid,
.main-content,
#root main.inner-wrapper div.ui.container {
  min-height: 60vh;
  background-color: transparent;
}

button:disabled {
  opacity: 0.5;
}

.footer ul li,
ul,
ol,
figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.main-content aside ol,
.main-content aside ul,
.main-content .body ol,
.main-content .body ul {
  padding-inline-start: 2em;
}

@media (min-width: 1024px) {
  .main-content .body .columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2em;
  }
}

.contribute .main-content aside ul {
  padding-bottom: 2em;
}

figure {
  margin: 0;
  max-width: 100%;
}

ul.menu {
  margin: 0 0 0 2em;
  padding: 0;
  list-style: none;
}

ul.main-menu li {
  list-style: none;
  font-size: 1.5em;
}

.gallery .container > h1.main {
  text-transform: uppercase;
}

.gallery .container > h1.main {
  position: relative;
  top: 1em;
}

#root header .main-nav {
  user-select: none;
}

#root header .main-nav i.bars {
  font-size: 2rem;
  position: absolute;
  top: 0.5em;
  right: 2%;
  transition: transform 0.5s ease-in-out;
}

.inner-wrapper a {
  color: var(--dark-green);
}

.footer ul a:hover,
main.inner-wrapper a:hover {
  text-decoration: underline;
}

header nav.main-nav a {
  display: block;
}

.main-content,
article,
header nav.main-nav i.icon,
header nav.main-nav a {
  position: relative;
}

.call-to-actions {
  position: relative;
  display: block;
}

.call-to-actions > div {
  width: 45%;
  margin: 0 1.25em;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border: solid 1px white;
}

.call-to-actions,
.call-to-actions > div {
  height: 22.5vw;
}

.call-to-actions > div.first {
  float: left;
}

.call-to-actions > div.second {
  float: right;
}

#root aside .column {
  display: flex;
  flex-flow: column wrap;
}

#root .column .uri-selector .ui.selection.dropdown {
  min-width: 8em;
}

#root .uri-group > .icon.plus {
  font-size: 1.25em;
  clear: both;
  margin: 0.5em 0;
  align-self: flex-end;
  color: #009900;
  cursor: pointer;
}

#root .column .uri-selector .uri > .icon {
  margin: .5em 0 0 .5em;
  cursor: pointer;
}

#root .column .uri-selector .uri .icon:hover {
  color: #990000;
}

#root .column .uri-selector .ui.selection.dropdown .menu {
  max-width: 8em;
}

#root .column .uri-selector .ui.input > input {
  width: calc(100% - 8em);
}

#root .column .uri-selector > .uri {
  width: 100%;
}

#root .column .uri-selector .ui.selection.dropdown .menu >.item {
  padding-right: 0;
}

.call-to-actions > div > a {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0.375em;
  font-size: 4vw;
  background-color: var(--white-trans-80);
  color: var(--dark-brown);
  transition: all 0.5s ease-in-out;
}

.call-to-actions > div > a:hover {
  text-decoration: none;
  color: var(--medium-blue);
  background-color: var(--white-trans-60);
}

.call-to-actions > div > a span {
  display: inline-block;
  text-align: center;
  line-height: 1em;
}

.call-to-actions > div > a span:first-child {
  margin-bottom: 0.125em;
}

.call-to-actions > div > a span:last-child {
  text-transform: uppercase;
}

.call-to-actions > div.first > a span:last-child {
  color: var(--dark-green);
}

.call-to-actions > div.second > a span:last-child {
  color: var(--medium-blue);
}

.call-to-actions > .contribute-cta {
  background-image: url(/images/call-to-action-pic1.jpg);
}

.call-to-actions > .gallery-cta {
  background-image: url(/images/call-to-action-pic2.jpg);
}

#root i.icon.close:before,
#root i.icon.arrow.right:before,
#root i.icon.arrow.left:before {
  font-family: 'Montserrat', sans-serif;
}

#root i.icon.arrow.left:before {
  content: '\2190';
}

#root i.icon.arrow.right:before {
  content: '\2192';
}

#root i.icon.close:before {
  content: '\00D7';
}

header nav.main-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

header nav.main-nav ul.menu {
  position: absolute;
  max-height: 0;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  margin-left: 0;
  padding-top: 3em;
}

header.show-menu i.bars,
header.show-menu .user-details {
  z-index: 101;
}

header.show-menu i.bars {
  transform: rotate(90deg) translate(25%, -25%);
}

header nav.main-nav ul li i.icon {
  margin: 0 0 0 0.5em;
}

footer.footer .footer-menu {
  margin-top: 3.5em;
}

footer.footer .credit-links {
  margin-bottom: 2em;
}

footer.footer .footer-menu li {
  margin: 1em auto;
  text-align: center;
}

footer.footer .credit-links li {
  margin: 0.75em auto;
  text-align: center;
}

time,
i.icon,
header nav.main-nav ul li .logged-out,
.logged-in header nav.main-nav ul li .logged-in {
  display: inline-block;
  position: relative;
}

.logged-in header nav.main-nav ul li .logged-out,
header nav.main-nav ul li .logged-in {
  display: none;
}

header nav.main-nav a.contribute {
  display: inline-block;
  width: auto;
  color: var(--medium-blue);
  border-radius: 0.25em;
  background-color: var(--white-trans-33);
}

article.post > .text-content,
.main-area,
aside {
  position: relative;
}

.main-content > .text-content {
  margin-top: 1.75rem;
  margin-bottom: 2rem;
}

.main-content article.post {
  margin: 0 0 2.25em 0;
}

.main-area img {
  max-width: 100%;
}

.wrapper img {
  user-select: none;
}

.ui.segments {
  margin: 0;
}

.inner-wrapper > .main-content > .segments {
  margin-top: 1rem;
}

#root .segments article {
  clear: both;
}

#root .segments article .text-content {
  top: 0;
}

#root .ui.horizontal.segments {
  background-color: transparent;
}

.right-col-offset .main-content .main-area {
  padding: 0.75em 0 1em 0;
}

.right-col-offset .main-content .half,
.right-col-offset .main-content .two-thirds {
  width: 100%;
}

#masonry-grid {
  position: relative;
  margin: 0.5% -0.5%;
}

#column-images {
  flex-flow: column wrap;
}

#column-images > figure {
  margin: 0 auto 3vw auto;
}

.media figure,
.media figure img {
  max-width: 100%;
}

.right-col-offset .main-content .main-area .wp-block-image img,
.right-col-offset .main-content .main-area img.large {
  width: 100%;
  height: auto;
}

#root .gallery .top-bar .num-images,
#root .gallery .top-bar .num-artists,
#root .gallery .top-bar .artists {
  opacity: 0;
}

#root .gallery .top-bar .sort-row {
  display: flex;
  white-space: nowrap;
  align-self: flex-end;
  position: relative;
  top: -0.375rem;
}

#root .gallery .top-bar.sort-default .icon:first-child,
#root .gallery .top-bar.sort-newest .icon.down,
#root .gallery .top-bar.sort-oldest .icon.up {
  color: blue;
}

#root .gallery .top-bar .sort-row .icon {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  margin-left: 0.125em;
}

#root .gallery .top-bar .sort-row .icon:hover {
  transform: scale(1.2);
}

.right-col-offset .main-content .extra-area {
  margin-left: 3em;
  width: calc(100% - 3em);
}

.wp-block-embed__wrapper iframe {
  width: 100%;
  max-width: 100%;
}

.right-col-offset .extra-area .wp-block-embed__wrapper iframe {
  height: calc(50.625vw - 1.6875em);
}

.main-area > .wp-block-image,
.extra-area > .wp-block-embed__wrapper {
  margin-bottom: 1em;
}

.right-col-offset .main-content > aside {
  padding: 0.5em 0 1em 0;
  width: 100%;
}

.news .right-col-offset .main-content > aside {
  min-height: 80rem;
}

aside article {
  clear: both;
}

.right-col-offset .main-content > .main-area > h2,
.main-content > h1,
.right-col-offset .main-content > aside > h1 {
  font-size: 2rem;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  position: relative;
}
h1 > time,
h1 > em,
h1 > span {
  display: inline-block;
}

h2 > span {
  margin-right: 1.5em;
}

h2 > span:last-child {
  margin-right: 0;
}

.main-content > time,
.main-content > aside > time {
  font-weight: bold;
}

.main-content h1 > span.title {
  top: 0.125em;
  position: relative;
}

.right-col-offset .main-content > .main-area > h2 {
  width: 100%;
}

.news .main-content > h1,
.right-col-offset .main-content > h1,
.right-col-offset .main-content > aside > h1 {
  position: absolute;
  left: -1rem;
  padding-top: 0.25em;
}

@media (min-width: 880px) {
  .folders .container>h1,
  .curated .container > h1 {
    position: absolute;
    top: 2.5rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    left: 0;
    font-size: 1.2em;
    width: 2em;
    z-index: 14;
    margin-top: 0;
    padding-top: 0;
  }
}

h1 span, h2 span, p span {
  display: inline-block;
}

@media (max-width: 879px) {
  .folders .container > h1,
  .curated .container > h1 {
    position: relative;
    text-align: left;
  }
  h1 span, h2 span, p span {
    margin-right: 1em;
    letter-spacing: -1px;
  }
}


@media (max-width: 879px) {
  .folders .container > h1,
  .curated .container > h1 {
    font-size: 1.75rem;
  }
}

@media (max-width: 659px) {
  .folders .container > h1,
  .curated .container > h1 {
    font-size: 1.5rem;
  }
}


.right-col-offset .main-content > aside.side-panel > h1 {
  left: 0;
}

.news .main-content > h1 {
  width: 10em;
  top: 11em;
  left: 0.375em;
  text-align: right;
  text-transform: uppercase;
}

#root .news main.inner-wrapper div.ui.container {
  padding-top: 1em;
}

.news .news-items {
  margin-left: 3em;
}

.inner-content.top-content > .text-content {
  min-height: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.news-items article .text-content h3 {
  margin: 0.5rem 0;
  float: left;
}

.news-items article .text-content time {
  display: block;
  float: right;
  margin: 0.5rem 0 0.5rem 2rem;
}

.news-items article .text-content .body {
  clear: both;
}

.right-col-offset .main-content > h1,
.right-col-offset .main-content > aside > h1 {
  width: 1.25em;
}

.right-col-offset .main-content > h1 {
  top: 2.5rem;
}

.main-content > .text-content,
.right-col-offset .main-content > time,
#root .right-col-offset .main-content > aside > .login-form,
.right-col-offset .main-content > aside > article,
.right-col-offset .main-content > aside > time {
  margin-left: 3rem;
}

.right-col-offset .main-content > aside.contribute-form {
  pointer-events: none;
}

.right-col-offset .main-content > aside.contribute-form > .fields,
.right-col-offset .main-content > aside.contribute-form > article {
  margin-left: 0;
  pointer-events: all;
}

.right-col-offset .main-content > article.text-content {
  margin-left: 4.5rem;
}

.right-col-offset .main-content > aside,
.right-col-offset .main-content > article.text-content {
  max-width: calc(100% - 3rem);
}

.right-col-offset .main-content > aside > time {
  margin-bottom: 1em;
}

#root .right-col-offset .main-content > aside > .login-form {
  margin-top: 0;
}

.gallery .ReactTags__selected {
  position: relative;
}

.gallery .ReactTags__selected .ReactTags__tag {
  position: absolute;
  bottom: 0.5em;
  left: 0.5em;
  z-index: 11;
}

.gallery .ReactTags__selected .ReactTags__tagInput {
  width: 100%;
  border: solid 1px var(--dark-green);
  max-width: var(--max-top-nav-width);
}

.gallery .ReactTags__selected .ReactTags__tagInput input {
  width: 100%;
  padding: 0 0.25em;
}

.gallery .is-filtered .ReactTags__selected .ReactTags__tagInput input {
  opacity: 0;
}

#root ul.main-menu li {
  line-height: 1.25em;
}

ul.main-menu li a {
  color: white;
  display: inline-block;
  padding: 0;
  padding: 0 0.25em;
}

ul.main-menu li a {
  padding: 0.125em 0.25em;
}

ul.main-menu li:hover a,
ul.main-menu li.active a {
  border-radius: 0.25em;
}

ul.main-menu li:hover a {
  background-color: var(--dark-green-trans);
}
ul.main-menu li.active a {
  background-color: var(--dark-green);
}

ul.main-menu li.contribute:hover a {
  background-color: var(--white-trans-60);
}

ul.main-menu li.contribute.active a {
  background-color: white;
}

.wrapper,
#root {
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.wrapper {
  max-width: 100%;
}

#root div.ui.container,
.outer-frame,
.wrapper .inner-wrapper {
  max-width: 1600px;
}

.outer-frame,
.wrapper .inner-wrapper {
  position: relative;
  margin: 0 auto;
}

.outer-frame > header {
  padding: 1.5em 5% 1em 5%;
}

i.mail:hover {
  color: #009900;
}

.custom.login-form .actions i.icon {
  transition: transform 0.5s ease-in-out;
}

.custom.login-form .form-inner {
  width: 100%;
}

#root .custom.login-form .actions i.icon:hover,
.mail-login-mode i.mail {
  transform: skew(-7.5deg);
}

i.google:hover {
  color: #cc0000;
}

i.facebook:hover {
  color: #000099;
}

header .user-details i.icon,
.login-buttons i.icon {
  margin-left: 0.5em;
}

header .user-details i.icon {
  font-size: 1.25rem;
}

.login-buttons i.icon {
  font-size: 2rem;
  transition: color 0.5s ease-in-out, transform 0.5s ease-in-out;
  cursor: pointer;
}

#root .ui.segments {
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.gallery .react-tags input::placeholder {
  color: var(--dark-green);
}

.container .user-images figure > i.check {
  position: absolute;
  font-size: 1rem;
  top: 0.5em;
  right: 0.5em;
  opacity: 0;
  color: green;
}

#root .container .user-images figure.active > i.check {
  font-size: 1.8rem;
  right: 2em;
  top: 0.25em;
}

#root .container .user-images figure:hover > i.check {
  opacity: 0.5;
}

#root input.textfield,
#root input.password,
.edit-panel .ReactTags__tagInputField,
.edit-panel textarea {
  border: solid 1px var(--dark-green);
  padding: 0.125em;
  background: none;
}

#root a.no-images,
#root .edit-panel.disabled .quill {
  pointer-events: none;
}

#root .edit-panel.disabled .quill {
  background-color: var(--faint-grey);
}

#root .edit-panel .quill {
  min-height: 20em;
}

#root .edit-panel .quill  .ql-editor {
  min-height: 21.75em;
}

#root .edit-panel .quill,
#root .edit-panel select,
#root .edit-panel option,
#root .edit-panel input {
  background-color: white;
  color: var(--dark-brown);
}

#root .edit-panel.disabled .react-tags .ReactTags__remove,
#root .edit-panel.disabled .ReactTags__tagInputField {
  display: none;
}

#root .edit-panel.disabled input.textfield,
#root .edit-panel.disabled textarea {
  border-color: transparent;
}

.edit-panel h4 {
  margin: 0;
}

.container .horizontal {
  display: block;
}

#root .login-form,
#root .user-details {
  height: 2em;
  display: flex;
  flex-flow: row nowrap;
  margin: 1.5em 0;
  clear: both;
}

#root .login-form {
  align-items: center;
}

#root .footer-menu li.signup  {
  position: relative;
}

#root .footer-menu li.signup .text-action {
  cursor: pointer;
}

#root .footer-menu li.signup .loader {
  position: absolute;
  left: 2em;
  top: -8rem;
  transform: scale(3);
}

@media screen and (max-width: 660px) {

  #root aside .login-form {
    flex-flow: row wrap;
    height: 3.5em;
  }

  #root aside .login-form .label {
    margin-bottom: 0.75em;
  }
}

#root .user-details {
  margin: 0.5em 0;
}

#root .user-details img {
  height: 100%;
  width: auto;
}

#root .login-form > .login-buttons {
  display: block;
  white-space: nowrap;
}

#root .login-form > .login-buttons,
#root .user-details > i.log,
#root .user-details > button {
  margin-left: 1em;
}

#root .user-details i,
#root .user-details button,
#root .user-details span {
  display: inline-block;
  white-space: nowrap;
}

#root .user-details i.user {
  margin-left: 0;
  overflow: visible !important;
}

#root .user-details > .user-display {
  display: inline-block;
  font-size: 0.75em;
  margin: 0.5em;
  text-align: right;
}

header i.icon {
  cursor: pointer;
}

header .logo {
  position: relative;
  float: left;
  width: 66.667%;
  height: 6em;
  background-position: top left;
  background-size: contain;
  background-image: url(/images/tgi-tree.svg);
}

.backdrop .back,
header .logo {
  background-repeat: no-repeat;
}

header .main-nav .user-widget {
  position: absolute;
  top: 0;
  right: 2.5%;
}

.logo figcaption {
  position: absolute;
  top: 0;
  left: 7.5em;
  color: white;
  bottom: 0;
  display: flex;
  flex-flow: row nowrap;
}

.logo figcaption h1 {
  position: absolute;
  top: 0.25rem;
  left: 0;
  width: 10em;
  font-size: 1.8em;
  display: flex;
  flex-flow: column wrap;
  line-height: 1.125em;
  color: var(--dark-green);
  text-transform: uppercase;
  overflow: visible;
}

.logo figcaption h1 span {
  display: inline-block;
}

.logo figcaption h2 {
  position: absolute;
  bottom: -0.25em;
  left: 0;
  width: 16em;
  max-width: 50vw;
  font-size: 1.5em;
  font-weight: normal;
  white-space: nowrap;
}

.logo figcaption h2.strapline {
  margin-left: 0;
  margin-bottom: -1.5em;
}

#root .wrapper main {
  clear: both;
}

#root .login-form p {
  margin-block-start: 0;
  margin-block-end: 0;
}

#root .login-form p.label {
  min-width: 5em;
  font-size: 1.25em;
  user-select: none;
}

@media (min-width: 1024px) {
  #root .login-form p.label {
    font-size: 1.5em;
    min-width: 4em;
    white-space: nowrap;
  }
}

@media (min-width: 1320px) {
  #root .login-form p.label {
    font-size: 1.75em;
    min-width: 3em;
  }
}

@media (min-width: 1440px) {
  #root .login-form p.label {
    font-size: 2em;
    min-width: 2.5em;
  }
}

#root .custom.login-form {
  flex-flow: column nowrap;
  height: auto;
}

#root .custom.login-form input.password,
#root .custom.login-form input.textfield {
  margin: 0.25em 0;
}

aside.text-content > p.message {
  margin-bottom: 1em;
  padding-bottom: 1em;
  margin-left: 3rem;
  border-bottom: dashed 1px var(--dark-green);
}

aside.text-content ul.error,
aside.text-content > p.error {
  color: red;
}

aside.text-content p.message {
  color: green;
}

#root .custom.login-form .actions i.icon {
  font-size: 1.375rem;
}

#root .custom.login-form .actions > .submit,
#root .custom.login-form .actions p.forgotten,
#root .custom.login-form .actions strong {
  position: relative;
  font-weight: normal;
  cursor: pointer;
  user-select: none;
}

#root .custom.login-form .actions > .submit,
#root .custom.login-form .actions > strong {
  display: block;
  width: 100%;
  max-width: 20em;
  border: solid 1px var(--dark-green);
}

aside .actions {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

#root .login-form .actions {
  display: block;
}

#root aside .edit-panel p.help {
  margin: 0.625em 0 0.125em 0;
  padding-bottom: 0;
}

#root aside .edit-panel .suggestions li {
  cursor: pointer;
  margin: 0.125em 0;
  padding: 0.125em 0;
}

#root aside .edit-panel .suggestions li:hover {
  color: var(--dark-green);
}

#root aside .edit-panel .coordinates i.icon {
  cursor: pointer;
  transition: transform 0.333s ease-in-out;
}

#root aside .edit-panel .coordinates:hover i.icon {
  color: var(--dark-green);
}

#root aside .edit-panel .coordinates i.icon:hover {
  transform: scale(1.2);
}

#root aside .actions .icon.save::before {
  content: "";
}

#root aside .actions .icon.save::after {
  content: "\F0C7";
}

#root aside .actions .icon.save span {
  margin-right: 0.5em;
}

#root .uploader .expand-text:hover,
#root .custom.login-form .actions > .submit:hover,
#root .custom.login-form .actions > strong:hover {
  background-color: var(--dark-green);
  color: white;
}

#root .custom.login-form .actions > strong {
  padding: 0.25em 0.5em;
  margin: 1.5em 0;
}

#root .custom.login-form .actions > .submit {
  padding: 0.25em 0.25em 0.25em 2.5em;
  margin: 0.5em 0;
}

#root .custom.login-form .actions > .submit i.icon {
  position: absolute;
  top: 0;
  left: 0.25em;
}

#root .custom.login-form .actions strong:hover {
  font-style: italic;
}

#root .custom.login-form input {
  width: 100%;
  max-width: 20em;
}

#root .horizontal figure.segment {
  position: relative;
  display: block;
  height: auto;
  border: none;
  box-shadow: none;
  overflow: hidden;
  z-index: 2;
  background-color: white;
  border-radius: 0.125em;
}

#root .main-images > .video-toggle,
#root .horizontal figure.segment .icon.video {
  opacity: 0.5;
  z-index: 20;
  transition: opacity 0.25s ease-in-out;
}

#root .horizontal figure.segment .icon.video {
  position: absolute;
  bottom: 0.5em;
  left: 0.375em;
}

#root .horizontal figure.segment .icon.images {
  position: absolute;
  top: 0.25em;
  right: 0.375em;
  z-index: 20;
  transform: scale(1.5);
}

#root .main-images>.video-toggle {
  position: fixed;
  font-size: 2rem;
  bottom: 2.25rem;
  left: 0.375em;
}

#root .horizontal figure.segment:hover .icon.video {
  opacity: 1;
}

#root .main-images>.video-toggle:hover {
  color: #009900;
}

#root .user-media-list {
  display: block;
}

#root .user-media-list figure.segment {
  background-color: transparent;
  position: relative;
}

#root .user-media-list figure.segment .handle {
  position: absolute;
  top: 0;
  left: 0;
  right: 60%;
  bottom: calc(50% - 2rem);
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  pointer-events: none;
  cursor: move;
}

#root .user-media-list figure.segment .handle .icon {
  display: inline-block;
  position: relative;
  font-size: 2.5rem;
  margin: 0.25em 0 0 0.375em;
}

#root .user-media-list figure.segment button {
  white-space: nowrap;
  width: 100%;
}

#root .user-media-list figure.segment:hover .handle {
  opacity: 1;
  pointer-events: all;
}

.mask {
  display: none;
}

#root .show-selection .user-media-list .mask,
#root .show-selection .user-media-list::before,
#root .show-selection .user-media-list::after {
  position: absolute;
  z-index: 100;
  opacity: 0;
  content: " ";
  user-select: none;
}

#root .show-selection .user-media-list::before,
#root .show-selection .user-media-list::after {
  bottom: 0;
}

#root .show-selection .user-media-list .mask {
  display: block;
  top: 0;
  height: 8em;
  width: 4.5em;
}

#root .show-selection .user-media-list::before {
  right: 3.5em;
  top: 0;
  width: calc(50% - 3.5em);
}

#root .show-selection .user-media-list::after {
  top: 3em;
  right: 0;
  width: 3.5em;
}


#masonry-grid .column > figure.segment {
  max-width: 100%;
  width: auto;
  flex: 1 1 auto;
  padding: 1%;
  margin: 3%;
  transition: transform 0.5s ease-in-out;
  border-radius: 0.125em;
}

#root .show-large-image .horizontal > figure.segment.active,
.message-pane {
  position: absolute;
  text-align: center;
}

#root .show-large-image .horizontal > figure.segment.active {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 51;
  padding: 0;
}

#root .show-selection .horizontal > figure.segment.active .handle {
  display: none;
}

#root .show-large-embed #user-media {
  display: flex;
}

#root .show-large-embed #user-media .active {
  width: 100%;
}

.message-pane {
  top: 3em;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.9);
  left: 2.5%;
  right: 2.5%;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.5s ease-in-out;
}

.message-pane .text {
  pointer-events: none;
}

.message-pane i.close {
  position: absolute;
  top: -0.625rem;
  right: -0.375rem;
  height: 1em;
  width: 1em;
  font-size: 2.5rem;
}

.message-pane i.close:hover {
  color: red;
}

.show-message .message-pane {
  z-index: 101;
  opacity: 1;
}

#root .show-selection .inactive {
  opacity: 0;
}

#root figure.inactive .icon.close {
  display: none;
}

#root .actions i.icon {
  font-size: 1.5rem;
  cursor: pointer;
  width: auto;
}

#root .actions i.icon .label {
  display: inline-block;
  font-size: 0.667em;
  margin-left: 0.25em;
}

#root .horizontal > figure.segment.active figcaption {
  opacity: 0;
}

figure.segment img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

#column-images figure.segment img,
.user-media-list figure.segment img {
  cursor: pointer;
}

.user-media-list figure .status {
  position: absolute;
  font-size: 0.667rem;
  top: 1em;
  left: 1em;
  padding: 0.125em 0.25em;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 60;
  opacity: 0;
}

.user-media-list figure:hover .status {
  opacity: 0.5;
}

.user-media-list figure .status .icon {
  margin-left: 1em;
}

.user-media-list figure .status .trash {
  display: none;
}

.gallery .top-bar {
  position: relative;
}

.top-bar .info span,
.top-bar .info em,
.user-media-list figure.active .status .trash {
  display: inline-block;
}

.user-media-list figure.active .status .trash:hover {
  color: red;
}

.user-media-list figure.active .status {
  font-size: 1rem;
  opacity: 1;
  top: 0.5em;
  left: 0.5em;
}

#root .gallery .top-bar > .react-tags {
  position: relative;
  width: 60%;
  max-width: var(--max-top-nav-width);
  align-self: flex-end;
  font-size: 1em;
  top: 0.125em;
}

#root .gallery .top-bar > .react-tags .textfield-long input {
  width: 100%;
  min-width: 100%;
}

#root .gallery .top-bar > .ReactTags__tagInput {
  margin: 0;
}

.top-bar .info span.small {
  font-size: 0.9em;
}

.top-bar .info span.label {
  margin-right: 0.5rem;
}

.top-bar .info span.filter {
  margin: 0 2em 0 0.25em;
}

.top-bar .info em {
  margin-right: 1em;
}

#root .gallery .top-bar .info {
  position: absolute;
  white-space: nowrap;
  font-size: 1.625em;
  top: 0.875rem;
  font-size: 1.125rem;
}

.main-images figure,
#column-images figure,
#masonry-grid figure {
  display: flex;
  flex-flow: row nowrap;
}

.main-images figure figcaption,
#column-images figure figcaption,
#masonry-grid figure figcaption {
  padding: 0.25em 5%;
  text-align: center;
  background: white;
  color: var(--dark-brown);
  transition: opacity 0.5s ease-in-out;
}

.main-images figure figcaption,
#column-images figure figcaption {
  position: relative;
  padding: 0.25em 5%;
  min-height: 5%;
}

#masonry-grid figure figcaption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

#masonry-grid figure:hover figcaption {
  opacity: 1;
}

.main-images figure figcaption {
  bottom: 5%;
}

.curations #masonry-grid figure figcaption {
  font-size: 0.75rem;
}

#curations-list figure > figcaption {
  padding-bottom: 2.25rem;
}

#curations-list figure .preview-image {
  position: relative;
  display: block;
}

#curations-list figure figcaption h3 {
  margin: 0.5em 0 0.25em 0;
}

#curations-list figure figcaption {
  position: relative;
  font-size: 1rem;
  margin: 0.5em 0;
}



figure figcaption .caption {
  margin: 0 0 0.25em 0;
  padding: 0 0 0.25em 0;
  display: flex;
  flex-flow: column nowrap;
}

.gallery #masonry-grid figure figcaption {
  font-size: 0.5rem;
}

.container .main-images figure figcaption > div,
.container .main-images figure figcaption .caption > span {
  display: block;
  text-align: left;
}

.container .main-images figure figcaption > div,
.main-images-container figure figcaption .caption .user-display-name
.container .main-images figure figcaption .caption > span.user-display-name {
  width: 100%;
}

.container .main-images figure figcaption > div pan,
.container .main-images figure figcaption > div em {
  display: inline-block;
  margin-right: 0.5em;
}

.container .main-images figure figcaption > div span:last-child,
.container .main-images figure figcaption > div em:last-child {
  margin-right: 0;
}

figure figcaption .caption .user-display-name {
  max-width: 10em;
}

.main-images-container figure figcaption .caption .user-display-name {
  max-width: 100%;
}

.image-set-container figure figcaption .caption .user-display-name {
  max-width: 100%;
}

figure figcaption .caption .label {
  font-size: 0.9em;
}

figure figcaption p {
  margin-block-start: 0;
  margin-block-end: 0;
}

figure figcaption p > span {
  display: inline-block;
}

figure figcaption p.description {
  margin-top: 0;
}

figure figcaption .caption .title {
  text-transform: uppercase;
  font-weight: bold;
}

figure figcaption p > span.right {
  float: right;
  margin-left: 2em;
}

figure figcaption .tags {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin: 0.25em 0 0.75em 0;
  align-content: flex-start;
  justify-content: flex-start;
  font-style: italic;
  margin: 0.25em 0 0 0;
  padding: 0.25em 0 0 0;
}

figure figcaption .tags li {
  margin: 0 0.5em 0 0;
  padding: 0 0.5em 0 0;
  list-style: none;
  border-right: solid 1px white;
}

figure figcaption .tags li:last-child {
  padding: 0;
  border-right: none;
}

figure.segment figcaption p {
  clear: both;
  margin: 0;
  padding: 0 0 0.125em 0;
}

#root .container.thumbnails figure.segment:hover {
  transform: scale(1.125);
  z-index: 50;
}

.user-media-list > figure.inactive img,
#masonry-grid figure.segment:hover img,
.container.thumbnails figure.segment:hover {
  cursor: zoom-in;
}

.user-media-list {
  display: block;
}

.user-media-list > figure {
  break-inside: avoid;
}

#root .user-images figure.segment.active,
.user-media-list figure.segment:hover {
  transform: scale(1);
}

#root .main-content .show-large-image > #user-media,
#root .main-content .show-large-embed .add-embed-wrapper {
  max-height: 0;
  min-height: 0;
  opacity: 0;
  overflow: hidden;
}

#root .user-images.show-selection.show-large-image figure.segment.active {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
}

#root .user-images.show-selection .add-embed-button {
  opacity: 0;
  pointer-events: none;
}

#root .user-images.show-selection .icon.close {
  pointer-events: all;
}

#root .show-large-embed #user-images figure {
  pointer-events: none;
}

#root .user-images.show-selection figure.segment.inactive {
  opacity: 0;
}

#root .user-images.show-selection figure.segment.active figcaption {
  display: none;
}

#root .user-images.show-selection .horizontal {
  min-height: calc(100vh - 7.5em);
  max-height: calc(100vh - 5em);
}

#root .user-images figure.segment.active .icon {
  font-size: 1rem;
  z-index: 59;
}

#root .user-images figure.segment.active .icon.close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  padding: 0.25em 0.5em;
  height: 2em;
  width: 2em;
  border-radius: 1em;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.33s ease-in-out;
  cursor: pointer;
}

#root .user-images.show-selection figure.inactive {
  display: none;
}

#root .user-images figure.segment.active .icon.close:hover {
  color: red;
}

#root .user-images figure.segment.active .icon.close:before {
  position: absolute;
  top: 0;
  left: 0.375em;
  font-size: 1.5rem;
}

#root .user-images figure.segment.active .icon.close:hover {
  transform: scale(1.33);
}

#root .user-images figure figcaption {
  font-size: 0.75rem;
}

#root .textarea,
#root .react-tags,
#root .textfield-long {
  width: 100%;
}

#root .textfield-long {
  margin: 0.5em 0;
}

#root .cloudinary-button {
  width: 100%;
  font-size: 1em;
  text-transform: uppercase;
  box-shadow: none;
  background-color: var(--dark-green);
  color: white;
  border-radius: 0;
  transition: background-color 0.25 ease-in-out;
}

.uploader .agree-to-terms-row {
  margin: 1em 0;
  color: green;
}

.sign-up-row {
  margin: 0.75em 0;
}

.sign-up-row input {
  margin-right: 0.25em;
}

.uploader .expand-text,
.uploader #agree-to-terms {
  margin-right: 0.25em;
}

.uploader .expand-text {
  cursor: pointer;
  padding: 0.25em 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  border: solid 1px var(--dark-green);
}

.uploader .text {
  margin-top: 1em;
}

#root .cloudinary-button:hover {
  background-color: #999999;
}

#root .clear {
  clear: both;
}

#root footer.footer {
  position: relative;
  width: 100%;
  clear: both;
}

#root footer.footer > section {
  min-height: 18em;
  padding: 1em 0;
}

footer.footer > .bottom > .inner-wrapper > .inner {
  background-color: var(--white-trans);
}

footer.footer section.top a,
footer.footer section.top li {
  color: var(--medium-blue);
}

footer.footer section.bottom .inner h2 {
  font-size: 2em;
  text-transform: uppercase;
  color: var(--medium-blue);
}

footer.footer section .inner-wrapper .inner {
  padding: 1em 5%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

#root .cloudinary-button i.icon {
  margin-left: 0.5em;
  font-size: 1.25rem;
}

#root .react-tags > input,
#root .react-tags .react-tags__search {
  width: 100%;
}
#root .react-tags .textfield-long {
  display: inline-block;
  position: relative;
  margin: 0.5em 0;
  width: 100%;
  background-color: white;
  height: 1rem;
}

#root .edit-panel .react-tags .textfield-long {
  height: 2rem;
  width: 100%;
}

#root .edit-panel .react-tags .textfield-long input {
  min-width: 100%;
}

#root .react-tags .textfield-long input {
  padding: 0.125em;
  border: solid 2px var(--dark-green);
  line-height: 1.25em;
  font-size: 1.25em;
}

#root .react-tags .react-tags__suggestions {
  position: absolute;
  top: 1.75em;
  left: 0;
  color: #999999;
  background: white;
  padding: 0.25em 0.25 0.375em 0.25em;
  border: solid 1px #999999;
  z-index: 20;
}

#root .top-bar .react-tags__selected {
  position: absolute;
  top: 0.875em;
  left: 0.375em;
  z-index: 40;
}

#root .edit-panel .react-tags__selected {
  position: relative;
  top: 0.375em;
}

#root .gallery .react-tags-wrapper .react-tags__suggestions li:hover {
  background-color: #fafafa;
}

#root .react-tags__search .react-tags__suggestions {
  margin: 1em 0 0 0.125em;
  width: calc(100% - 0.25em);
}

#root .react-tags__search .react-tags__suggestions ul,
#root .react-tags__search .react-tags__suggestions li {
  list-style: none;
  margin: 0;
}

#root .react-tags__search .react-tags__suggestions ul {
  padding: 0;
  min-width: 100%;
  width: 100%;
}

#root .react-tags__search .react-tags__suggestions li {
  padding: 0.25em 0;
  min-width: 100%;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  font-size: 0.9rem;
  padding: 0.125em 0.25em;
  font-weight: normal;
}

#root .actions i.save:hover,
#root .react-tags__search .react-tags__suggestions li:hover {
  color: #000099;
}

#root .react-tags-wrapper input {
  min-width: 10em;
}

#root .react-tags-wrapper .ReactTags__remove {
  font-size: 1.5rem;
  margin: 0 1em 0 0.375em;
  cursor: pointer;
  color: red;
}

#root .gallery .react-tags-wrapper .ReactTags__remove {
  font-size: 2.5rem;
  position: absolute;
  top: -0.5em;
  right: -1.75em;
  filter: grayscale(100%);
  transition: filter 0.333s ease-in-out;
}

#root .gallery .react-tags-wrapper:hover .ReactTags__remove {
  filter: grayscale(0%);
}

#column-images figure.segment figcaption {
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 1rem;
  display: flex;
  flex-flow: row wrap;
}

#column-images figure.segment figcaption p {
  margin-right: 2em;
}

#column-images figure.segment:hover figcaption,
#masonry-grid figure.segment:hover figcaption {
  opacity: 1;
}

.menu li strong,
.menu li em {
  display: inline-block;
}

.menu li em {
  margin-left: 0.5em;
}

.footer .logos {
  display: grid;
  width: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0.5em 0;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2.5%;
  max-width: 100%;
}

.footer .logos,
.footer .logos figure {
  position: relative;
}

.footer .logos figure {
  padding: 1em 5% 1.5em 5%;
  object-fit: contain;
}

.footer .logos figure img {
  position: relative;
  width: auto;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}

.footer .logos figure img.somerset-house {
  transform: scale(1.5);
}

.footer .top .inner {
  height: 10rem;
}

.footer h2 {
  text-align: center;
  margin: 0 auto;
  color: var(--dark);
  font-size: 1.25em;
  font-weight: normal;
}

.footer h2 > span:first-child {
  text-transform: uppercase;
}

.footer .top .inner h2.side-title {
  position: absolute;
  top: 0;
  left: 2%;
  transform: rotate(270deg) translate(-10rem, 0.5rem);
  transform-origin: 0 0;
  width: 10rem;
  overflow: hidden;
}

.footer .logos figure figcaption {
  position: absolute;
  left: -999em;
  top: 0;
}

.container .swipeable {
  display: none;
}

.main-images-container,
.image-set-container,
.container .image-set-container,
.container .swipeable,
.container .backdrop {
  position: fixed;
}

.main-images-container figure {
  opacity: 0;
}

.show-main .main-images-container.active,
.show-main .image-set-container.active {
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.5s ease-in-out;
}

.show-main .image-set-container.active {
  z-index: 50;
}

.show-main .main-images-container.active {
  z-index: 30;
}

.main-images-container,
.image-set-container,
.container .backdrop,
.container .swipeable {
  left: 0;
  right: 0;
  bottom: 0;
}

.main-images-container,
.image-set-container,
.container .backdrop {
  top: 0;
  z-index: -1;
  opacity: 0;
  background-color: var(--white-trans-75);
  color: white;
  z-index: -1;
  pointer-events: none;
}

#root .main-images-container {
  max-height: 100vh;
  overflow: hidden;
}



#root .main-images-container .main-images>figure {
  object-fit: contain;
  object-position: center;
  position: absolute;
  display: grid;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: opacity .9s ease-in-out;
  opacity: 0;
}

#root .show-main .main-images-container .main-images > figure {
  top: 2vw;
  left: 2vw;
  right: 2vw;
  bottom: 2vw;
  width: 96%;
}

#root .show-main .main-images-container .main-images>figure.active {
  opacity: 1;
}

.main-images figure ul.web-links,
.image-set-container figure ul.web-links {
  list-style: none;
  margin: 1em 0;
  padding: 1em 0;
  display: flex;
  flex-flow: column nowrap;
  justify-items: flex-start;
  align-items: flex-start;
}

.main-images figure ul a:hover,
.image-set-container figure ul a:hover {
  text-decoration: none;
}

.main-images figure figcaption .icon,
.image-set-container figure figcaption .icon {
  font-size: 1em;
  position: relative;
}

.container .backdrop .back {
  background-position: center center;
  background-size: 70%;
}

.show-main .backdrop {
  opacity: 1;
}

.backdrop .back {
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
  width: 5vw;
  height: 5vw;
  transition: transform 0.5s ease-in-out;
  transform: rotate(135deg);
}

.backdrop .back:hover {
  transform: rotate(-30deg);
}

.main-images,
.image-set-container {
  max-height: 0;
  box-sizing: border-box;
}

.show-main .image-set-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 52;
  max-height: none;
}

.show-main .main-images {
  /* display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column nowrap; */
  position: relative;
  display: block;
  overflow: auto;
  height: 100vh;
  max-height: none;
  padding: 4vw;
}

#root .main-images figure {
  object-fit: contain;
  object-position: center;
  position: relative;
  display: grid;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: scale(1, 1);
  transition: opacity 0.9s ease-in-out;
  margin: 0 0 2.5em 0;
}

#root .main-images figure picture {
  padding: 0 0 1.25em 0;
}

.show-main .main-images figure.active {
  opacity: 1;
  transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;
}

.show-main .main-images figure.inactive {
  opacity: 0;
  pointer-events: none;
}

.show-main .main-images.stable figure.inactive {
  transform: scale(-1, 1);
}

.show-main .main-images figure img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}

.show-main .main-images figure .media-play-overlay,
.show-main .main-images figure picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.container .main-images figure figcaption {
  max-width: 100%;
  width: 100%;
  justify-self: flex-end;
  align-self: flex-start;
  top: 6.25vw;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1em;
  background-color: var(--white-trans-80);
  opacity: 0;
  transition: opacity 0.333s ease-in-out;
}

.container .main-images figure:hover figcaption {
  top: 2.5%;
  opacity: 1;
}

.show-main .main-images figure .media-play-overlay {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items:  center;
}

.show-main .main-images figure .media-play-overlay .icon {
  font-size: 3em;
  opacity: 0;
  transition: opacity 0.333s ease-in-out;
  color: var(--dark-green);
  padding: 0.75em 0.6875em 0.75em 0.8125em;
  background-color: var(--white-trans-75);
  margin-top: -20%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items:  center;
  border-radius: 50%;
}

.show-main .main-images figure.active.show-embed .media-play-overlay .icon {
  padding: 0.75em;
  color: var(--red-label);
}

.main-images figure .video-overlay {
  opacity: 0;
  transition: opacity 0.333s ease-in-out;
}

.show-main .main-images figure.active.show-embed .video-overlay {
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  z-index: 50;
}

.show-main .main-images figure.active.show-embed picture {
  opacity: 0;
  pointer-events: none;
}

.show-main .main-images figure.active.show-embed .media-play-overlay {
  width: 6em;
  height: 8em;
  left: calc(100% - 6em);
  z-index: 120;
}

.show-main .main-images figure .media-play-overlay:hover .icon {
  opacity: 1;
}

@media screen and (min-width: 660px) {
  .show-main .main-images figure .media-play-overlay,
  .show-main .main-images figure picture {
    width: 60%;
  }

  .container .main-images figure figcaption {
    max-width: 35%;
    width: 35%;
    justify-self: flex-end;
    align-self: flex-start;
    top: 2.5%;
    padding-left: 0;
    padding-right: 0;
    opacity: 1;
    overflow: auto;
  }
}

.main-images .icon {
  position: fixed;
  transition: all 0.333s ease-in-out;
  opacity: 0.5;
  font-size: 8vw;
  font-style: normal;
  color: var(--dark-brown);
}

.show-main .main-images figure figcaption:hover,
.show-main .main-images figure.over figcaption {
  opacity: 1;
}

#root .main-images figure > i.outline {
  position: absolute;
  top: -0.125em;
  height: 1.5em;
  width: 1.5em;
  transform: scale(0.6667);
  opacity: 0.75;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  z-index: 100;
}

#root .main-images figure > i.heart {
  left: -0.375em;
  color: red;
}

#root .main-images figure > i.user {
  left: 1em;
  color: green;
}

.main-images figure .image-anchor {
  position: absolute;
  pointer-events: none;
  top: -4vw;
  left: 0;
  right: 0;
  height: calc(100vh - 4vw);
}

#root .main-images figure > .claim-form {
  position: absolute;
  top: 1em;
  left: 0;
  background: white;
  max-width: 26em;
  padding: 0.625em 0.625em 1em 0.625em;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.75s ease-in-out;
}

#root .main-images figure.show-claim-form > .claim-form {
  opacity: 1;
  pointer-events: all;
  z-index: 1000;
}


#root .claim-form .claim-submit {
  margin: 0.5rem auto 0.75em auto;
  border-radius: 1.5em;
  background-color: #f9f9f9;
  padding: 0.5em 1em;
}


#root .main-images figure.active:hover > i.outline {
  opacity: 0.6667;
  pointer-events: all;
  z-index: 123;
  user-select: none;
}

#root .main-images figure i.heart > span.num {
  color: white;
  font-size: 0.33em;
  position: absolute;
  bottom: -0.5em;
  display: inline-block;
  width: 4.5em;
  left: 0;
  text-align: center;
  font-weight: bold;
  transition: unset;
}



#root .main-images figure i.user > .help-text {
  user-select: none;
  position: absolute;
  top: 0.625rem;
  left: 2.75em;
  font-size: 0.5em;
  white-space: nowrap;
}


.container .main-images figure figcaption > div.social-share-buttons,
.main-images figure .social-share-buttons {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: center;
}

.main-images figure .social-share-buttons {
  margin-top: 0.625em;
  padding-top: 0.625em;
  border-top: dotted 2px var(--dark-brown);
  align-items: center;
}

#root .main-images figure.embed {
  justify-content: center;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}

#root .main-images figure.embed figcaption {
  width: 100%;
  justify-self: center;
  top: 0;
}

.rgudure .media-embed .play-media:hover,
.main-images figure .media-embed .play-media:hover .icon {
  cursor: pointer;
  color: var(--dark-green);
  opacity: 1;
}

.main-images figure .media-embed a {
  display: inline-block;
  margin-left: 2em;
}

.main-images figure .social-share-buttons .text-label {
  justify-self: flex-start;
  margin-right: 1.5em;
  position: absolute;
  top: 0.625em;
  left: 0;
  opacity: 0;
  transition: opacity 0.333s ease-in-out;
  pointer-events: none;
}

.main-images figure .social-share-buttons:hover .text-label {
  opacity: 1;
}

.main-images figure .social-share-buttons .SocialMediaShareButton {
  height: 1.25rem;
  width: 1.25rem;
  position: relative;
  margin: 0 0.5rem;
  outline: none;
}

.main-images figure .social-share-buttons .SocialMediaShareButton i {
  color: var(--dark-brown);
  transition: color 0.333s ease-in-out;
}

.main-images figure .social-share-buttons .SocialMediaShareButton:hover i {
  color: var(--dark-green);
  
}

.main-images figure .social-share-buttons i.icon {
  font-size: 1.25rem;
  position: relative;
}

#root .main-images figure.active:hover > i.outline:hover {
  transform: scale(0.9);
}

.main-images .icon:hover,
.main-images .icon:hover {
  opacity: 1;
  cursor: pointer;
}

.main-images .close {
  top: -0.625em;
  right: -0.375em;
  z-index: 56;
  font-size: 10vw;
}

.main-images .arrow {
  top: 7.5vh;
  bottom: 7.5vh;
  height: 85%;
  width: 10%;
  z-index: 131;
  outline: none;
  user-select: none;
  cursor: pointer;
}

.main-images .arrow:before {
  position: absolute;
  top: 47.5%;
}

.main-images .arrow.left:before {
  left: 0.125em;
}

.main-images .arrow.right:before {
  right: 0.125em;
}

.main-images .arrow.left {
  left: -2vw;
}

.main-images .arrow.right {
  right: -2vw;
}

.container .user-media-list figure figcaption {
  position: relative;
  color: var(--dark-green);
  left: 0;
  top: 0;
  background: none;
  opacity: 1;
  padding: 0.5em 0 1em 0;
}

.container .user-media-list figure figcaption > i.edit {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1rem;
}

.container .user-media-list figure figcaption .description {
  max-height: 10em;
  overflow: hidden;
}

.container .user-media-list figure:hover figcaption > i.edit {
  color: green;
}

.container .user-media-list figure figcaption p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 0;
  text-align: left;
}

.container .user-media-list figure figcaption p.needs-caption {
  opacity: 0.5;
}

.container .user-media-list figure .status .icon {
  cursor: pointer;
}

#root .container .user-media-list figure .icon.warning.standalone {
  position: absolute;
  top: 0.625em;
  right: 0.75em;
  transform: scale(2);
  font-size: 1rem;
  opacity: 0.375;
  transition: opacity 0.333s ease-in-out;
  pointer-events: none;
}

#root .container .user-media-list figure:hover .icon.warning.standalone {
  opacity: 0.625;
}

#root .container .user-media-list figure.active .icon.warning.standalone {
  display: none;
}

.container .user-media-list figure .icon.warning {
  color: red;
}

.container .user-media-list figure.large-placeholder figcaption p {
  text-align: center;
}

.container .user-media-list figure figcaption p.caption {
  margin-right: 1.5em;
}

figure figcaption p span,
figure figcaption p em {
  display: inline-block;
}

figure figcaption p .label {
  margin-right: 0.5em;
}

figure figcaption p em.label:after {
  content: ':';
}

.large-placeholder > i.icon {
  position: relative;
  font-size: 10rem;
  height: 1em;
  margin: 0;
  width: 100%;
  opacity: 0.5;
}

.container .user-media-list .large-placeholder {
  min-width: 10rem;
}

.container .user-media-list .large-placeholder > figcaption {
  margin: 0.5em 0 1em 0;
  text-align: center;
}

figure figcaption ul.tags > li::before   {
  content: '#';
}

#root .user-media-list > .video-overlay.active {
  align-self: flex-start;
  justify-self: flex-start
}

.user-media-list figure figcaption ul.tags li {
  border-right: solid 1px var(--dark-green);
  line-height: 1em;
}
.main-content > .segments {
  clear: both;
}
.user-media-list figure figcaption ul.tags {
  margin: 0.5em 0;
}

.user-media-list figure figcaption ul.tags li:last-child {
  border-right: none;
}

#masonry-grid.cols-1 > .column {
  width: 100%;
}

#masonry-grid.cols-2 > .column {
  width: 50%;
}

#masonry-grid.cols-3 > .column {
  width: 33.33%;
}

#masonry-grid.cols-4 > .column {
  width: 25%;
}

#masonry-grid.cols-5 > .column {
  width: 20%;
}

#masonry-grid.cols-6 > .column {
  width: 16.6666%;
}

.container .inner-content {
  position: relative;
}

.text-content table td,
.text-content table th {
  vertical-align: top;
  border-spacing: 0.25em;
  padding: 0.25em;
}

.text-content table tbody td {
  font-size: 0.9em;
}

.container .inner-content .left {
  float: left;
  margin-right: 2%;
}

.container .inner-content .left,
.container .inner-content .text-content {
  margin-left: 0.625rem;
}

.container .inner-content .text-content {
  font-size: 1.25em;
}

#curations-list {
  flex-flow: column wrap;
}

#curations-list > figure {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

#curations-list > figure img {
  min-width: 100%;
}

.main-area .wp-block-gallery li {
  list-style-type: none;
  margin-bottom: 1.5em;
}

.main-area .wp-block-gallery li figure img {
  width: 100%;
  height: auto;
}

.news-items .wp-block-gallery li {
  display: none;
}

.news-items .wp-block-gallery li:first-child {
  display: block;
}

@media screen and (min-width: 440px) {

  .logo figcaption h1 {
    font-size: 2.25em;
    top: 1rem;
  }

  .logo figcaption {
    left: 10em;
  }

  .logo figcaption h2.strapline {
    font-size: 1.75em;
  }

  #root .container #masonry-grid {
    display: flex;
    flex-flow: row nowrap;
  }

  #masonry-grid > .column {
    width: 50%;
    min-width: 49%;
  }
  header .logo {
    height: 8em;
  }
  .top-bg {
    height: var(--top-bg-height-l5);
  }
}
@media screen and (min-width: 660px) {
  .logo figcaption h2.strapline {
    margin-left: 6em;
    margin-bottom: -0.25em;
    font-size: 2em;
  }
  .logo figcaption {
    left: 10em;
  }
}
@media screen and (max-width: 659px) {
  .news .main-content > h1,
  .right-col-offset .main-content > h1,
  .right-col-offset .main-content > aside > h1 {
    left: -1rem;
  }


  .main-content > .text-content,
  .right-col-offset .main-content > time,
  #root .right-col-offset .main-content > aside > .login-form,
  .right-col-offset .main-content > aside > article,
  .right-col-offset .main-content > aside > time {
    margin-left: 2rem;
  }
  .right-col-offset .main-content > article.text-content {
    margin-left: 3rem;
  }
  body .footer .logos {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 660px) {

  .top-bg {
    height: var(--top-bg-height-l5);
  }

  .clouds-bg {
    top: var(--header-bottom-l5);
  }

  #masonry-grid > .column {
    width: 33.33%;
    min-width: 32.83%;
  }

  .logo figcaption h2.strapline {
    margin-left: 8em;
    margin-bottom: 0.25em;
  }

  .logo figcaption {
    left: 12em;
  }

  .logo figcaption h1 {
    font-size: 3em;
  }

  header .logo {
    height: 11em;
  }

  .main-images .icon {
    font-size: 6vw;
  }

  .main-images .arrow.left {
    left: -4vw;
  }

  .main-images .arrow.right {
    right: -4vw;
  }

  #root .main-images figure > i.outline {
    top: -0.5em;
    left: -0.5em;
  }

  #root .main-images figure > i.heart {
    left: -0.5em;
  }

  #root .main-images figure > i.user {
    left: 0.25em;
  }

  #root .main-images i.close {
    top: -0.375em;
    right: -0.125em;
  }

  #curations-list figure > figcaption,
  #curations-list figure > a {
    padding-right: 1.5rem;
    width: calc(100% - 1.5rem);
  }

  #curations-list {
    display: flex;
    flex-flow: row wrap;
    margin-left: 3rem;
    margin-right: -1.5em;
  }

  #curations-list > figure {
    width: 50%;
    max-width: 50%;
    margin: 0;
  }

  .hall-of-fame .container > h1 {
    left: 0.125em;
    font-size: 1.5em;
  }
  .user-media-list {
    column-count: 2;
  }

  .inner-wrapper .message-pane {
    left: 5%;
    right: 5%;
    font-size: 1.1667em;
  }
}

@media screen and (max-width: 879px) {
  #root .contribute .main-content aside.side-panel > h1 {
    font-size: 1.25rem;
  }
  #root header.show-menu nav.main-nav ul.menu {
    max-height: none;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.9);
    pointer-events: all;
    height: 100vh;
    z-index: 22;
  }

  #root header.show-menu nav.main-nav ul.menu li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  #root header.show-menu nav.main-nav ul.menu li h3,
  #root header.show-menu nav.main-nav ul.menu li a {
    color: black;
  }

  #root header.show-menu nav.main-nav ul.menu li a.contribute {
    background-color: var(--medium-blue-trans-33);
  }

  #root header.show-menu nav.main-nav ul.menu li:hover a.contribute {
    background-color: var(--medium-blue-trans-60);
  }

  #root header.show-menu nav.main-nav ul.menu li.active a.contribute {
    background-color: var(--medium-blue-trans-60);
  }

  #root header.show-menu nav.main-nav ul.menu li.active a,
  #root header.show-menu nav.main-nav ul.menu li:hover a {
    color: white;
  }

  #root .react-tags {
    visibility: hidden;
  }
  .text-contracted .uploader .text {
    max-height: 200em;
    transition: max-height 1s ease-in-out;
  }

  #root .text-contracted .uploader .text {
    max-height: 0;
    overflow: hidden;
  }

  .container .image-set-container.active .swipeable {
    display: block;
    user-select: none;
    z-index: 200;
    top: 60%;
  }

  .main-images .close {
    height: 3em;
    width: 3em;
  }
  .main-images .close:before {
    position: absolute;
    top: 0;
    right: 0.125em;
  }
  .footer .logos {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}


header .logo {
  font-size: 0.75em;
}

@media screen and (min-width: 880px) {

  .clouds-bg {
    position: absolute;
    top: var(--header-bottom-l3);
  }
  body .top-bg {
    height: var(--top-bg-height-l3);
  }

  header nav.main-nav {
    width: var(--max-top-nav-width-l3);
    margin-left: -2%;
    top: 5.625rem;
    left: auto;
    right: 5%;
    font-size: 0.6em;
  }

  .right-col-offset .main-content > aside.contribute-form > h2 {
    max-width: calc(100% - 8em);
  }

  .logo figcaption {
    left: 16em;
    flex-flow: column wrap;
  }

  .logo figcaption h2.strapline {
    margin-left: 0;
  }

  header nav.main-nav ul {
    font-size: 1.375em;
  }

 
  #root header .logo {
    font-size: 0.8em;
  }

  header .logo {
    height: 13.5em;
    max-width: 50%;
  }

  article.body,
  article > .body {
    font-size: 1.25em;
  }

  #root .mobile-only {
    display: none;
  }

  #root ul.main-menu {
    display: flex;
    flex-flow: row wrap;
  }

  #root ul.main-menu li {
    height: 1.25em;
  }

  #root ul.main-menu li.short {
    margin-right: 1em;
    width: auto;
    max-width: 5em;
    white-space: nowrap;
  }

  #root ul.main-menu li.short:last-child {
    margin-right: 0;
  }

  #root ul.main-menu li.long {
    width: 100%;
  }

  #root .main-menu li a {
    line-height: 1.25em;
  }

  #root header .main-nav i.bars {
    display: none;
  }


  #root .main-images {
    padding: 3vw;
  }

  #root .main-images figure > .image-anchor {
    top: -3vw;
    height: calc(100vh - 3vw);
  }

  .container .inner-content,
  #masonry-grid {
    padding-left: 3rem;
  }

  .inner-wrapper > .container > .inner-content {
    padding: 1.5rem 0 2rem 3rem;
  }

  #masonry-grid > .column {
    width: 25%;
    min-width: 24.5%;
  }

  #root .container .user-images {
    top: -1rem;
    min-height: 80vh;
  }

  #root .container .user-images.show-selection figure > div.preview {
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
    max-height: 100%;
    margin: 0 auto;
  }

  #root header .main-nav .user-widget {
    height: 1.5em;
  }

  .footer .top .inner h2.side-title {
    left: 5%;
  }

  footer.footer .footer-menu {
    margin-top: 0;
  }

  footer.footer .credit-links {
    margin-bottom: 0;
  }

  #root .user-details {
    margin: 0.5em 3em 0 3rem;
  }

  .right-col-offset .main-content > aside > h1 {
    left: -0.375em;
  }
  .right-col-offset .main-content .half,
  .right-col-offset .main-content .two-thirds {
    width: 50%;
    float: left;
    margin-left: 0;
  }

  .news .right-col-offset .main-content .two-thirds {
    min-height: 80rem;
  }

  #curations-list,
  .ui.segments.news-items {
    display: flex;
    flex-flow: row wrap;
    margin-right: -1.5em;
  }

  .news-items article.post {
    width: 50%;
    max-width: 50%;
  }

  .news-items article.post .media,
  .news-items article.post .text-content {
    padding-right: 1.5em;
  }

  .news-items article.post .media figcaption {
    display: none;
  }

  #root .segments.news-items article {
    clear: none;
  }

  .right-col-offset .two-thirds .wp-block-embed__wrapper iframe {
    height: 28.125vw;
  }

  .right-col-offset .main-content > aside {
    width: 45%;
    float: right;
    margin-left: -2.5%;
    margin-top: -0.125rem;
  }

  header nav.main-nav ul.menu {
    position: relative;
    max-height: none;
    height: 8.75em;
    opacity: 1;
    pointer-events: all;
    text-align: left;
    overflow: visible;
    padding: 0;
  }

  #root footer.footer > section {
    min-height: 8em;
  }

  .footer .logos figure {
    padding: 1em 5%;
  }

  #root header .user-details {
    margin: 0;
  }
  #root footer.footer .menu {
    display: flex;
    flex-flow: row nowrap;
  }
  #root footer.footer .footer-menu li {
    margin: 0.5em 1em;
    white-space: nowrap;
  }

  #root footer.footer .credit-links {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    font-size: 0.8em;
  }

  #root footer.footer .credit-links li {
    border-right: solid 1px white;
    padding: 0 1rem 0 0;
    margin: 1em 0.5rem;
  }

  #root footer.footer .credit-links li:last-child {
    border-right: none;
  }

  .user-media-list figure.segment > .video-overlay {
    max-width: 100%;
    aspect-ratio: 16/9;
  }

  .gallery .top-bar {
    display: flex;
    flex-flow: row nowrap;
    max-height: 3em;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  .gallery .top-bar .filter {
    cursor: pointer;
  }

  .gallery .top-bar .filter:hover {
    color: blue;
  }

  #root .gallery .top-bar .info {
    left: 3rem;
  }

  .gallery .container > h1.main {
    position: absolute;
    top: 4.75rem;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    white-space: wrap;
    margin-right: 1em;
    width: 1.25em;
    z-index: 14;
    margin-top: 0;
    padding-top: 0;
  }

  aside > h1 > span,
  .container > h1 > span {
    margin-bottom: 1em;
    width: 1em;
  }

  .gallery .container > h1.main .title {
    font-size: 1.3333em;
  }

  .gallery .container > h1.main span.filter,
  .gallery .container > h1.main .num-images {
    position: absolute;
    top: 0;
  }

  .gallery .container > h1.main span.filter {
    right: 22rem;
  }
  .gallery .container > h1.main .num-images {
    transform: rotate(90deg);
    right: 18rem;
    font-size: 0.6667em;
  }

  .main-images i.close,
  .main-images .icon {
    font-size: 4vw;
  }
  .main-images .close {
    font-size: 5vw;
  }

  #root .main-images figure > i.outline {
    top: 0.875em;
  }

  #root .main-images figure > i.heart {
    left: 0.875em;
  }

  #root .main-images figure > i.user {
    left: 1.875em;
  }

  #curations-list > figure {
    width: 33.333%;
    max-width: 33.333%;
  }
  #root .main-images figure > i.outline {
    top: -0.75em;
  }

  #root .main-images figure > i.heart {
    left: -0.75em;
  }

  #root .main-images figure > i.user {
    left: 0.375em;
  }

  #root .main-images i.close {
    top: -0.375em;
    right: -0.25em;
  }
  #column-images > figure {
    max-width: 90%;
  }
  .inner-wrapper .message-pane {
    font-size: 1.0625em;
  }
}

@media screen and (min-width: 1024px) {

  header nav.main-nav {
    /* top: 4.125rem; */
    top: 4.9375rem;
  }

  header nav.main-nav {
    font-size: 0.75em;
  }
  #root header .logo {
    font-size: 0.875em;
  }
  header ul.main-menu {
    margin-left: 3em;
  }

  #column-images > figure {
    max-width: 80%;
  }
}

@media screen and (min-width: 1100px) {

  header nav.main-nav {
    top: 3.625rem;
  }

  #root header .main-nav {
    width: var(--max-top-nav-width-l2);
  }

  body .top-bg {
    height: var(--top-bg-height-l2);
  }

  body .clouds-bg {
    top: var(--header-bottom-l2);
  }

  #root header .main-nav,
  #root header .logo {
    font-size: 0.9em;
  }

  #masonry-grid > .column {
    width: 20%;
    min-width: 19.5%;
  }
  ul.main-menu li {
    font-size: 1.5em;
  }

    #root .main-images {
      padding: 2vw;
    }

    #root .main-images figure > .image-anchor {
      top: -2vw;
      height: calc(100vh - 2vw);
    }

  .right-col-offset .main-content .two-thirds {
    width: 66.6667%;
    margin-top: 1.5rem;
  }

  .right-col-offset .main-content > aside {
    width: 30%;
    margin-top: 1.375em;
  }

  .right-col-offset .main-content > aside.contribute-form {
    width: 45%;
  }

  #curations-list > figure,
  .news-items article.post {
    width: 33.333%;
    max-width: 33.333%;
  }

  .right-col-offset .two-thirds .wp-block-embed__wrapper iframe {
    height: 33.75vw;
  }

  .gallery .top-bar h1 {
    width: 66.667%;
  }

  #curations-list > figure {
    width: 25%;
    max-width: 25%;
  }

  .container > .text-content,
  .container .inner-content .text-content {
    max-width: 66.6667%;
  }
  .user-media-list {
    column-count: 3;
  }
  .inner-wrapper .message-pane {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 1320px) {

  #root header .main-nav {
    width: var(--max-top-nav-width-l1-3);
  }

  body .clouds-bg {
    top: var(--header-bottom);
  }

  body .top-bg {
    height: var(--top-bg-height);
  }


  #root header .main-nav,
  #root header .logo {
    font-size: 1em;
  }

  #masonry-grid > .column {
    width: 16.666%;
    min-width: 16.166%;
  }
  ul.main-menu li {
    font-size: 1.625em;
  }

  #root .gallery .top-bar > .react-tags {
    width: 48%;
  }
  #column-images > figure {
    max-width: 75%;
  }
  

  .inner-wrapper .message-pane {
    font-size: 1.375em;
  }
}

@media screen and (min-width: 1440px) {

  #root header .main-nav {
    width: var(--max-top-nav-width-l1-2);
  }
  ul.main-menu li {
    font-size: 1.75em;
  }
}

@media screen and (min-width: 1500px) {

  #root header .main-nav {
    width: var(--max-top-nav-width);
  }

  ul.main-menu li {
    font-size: 1.833em;
  }
}

@media screen and (min-width: 1600px) {
  .right-col-offset .two-thirds .wp-block-embed__wrapper iframe {
    height: 540px;
  }
  .main-images i.close,
  .main-images .icon {
    font-size: 64px;
  }
  .main-images .close {
    font-size: 80px;
  }

  .wrapper > header {
    padding: 1.5em 80px 1em 80px;
  }
  #column-images > figure {
    margin-bottom: 48px;
  }

  .call-to-actions,
  .call-to-actions > div {
    height: 361px;
  }

  #root .call-to-actions > div a {
    font-size: 64px;
  }
}
